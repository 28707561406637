/**
 * @Author: Kuro
 * @Date:   2023-08-16 17:55:58
 * @Last Modified by:   Kuro
 * @Last Modified time: 2023-08-29 11:50:13
 */
export type Theme = 'legaltech' | 'ilta'

export const THEME: Theme = (() => {
  const envTheme = process.env.NEXT_PUBLIC_LTH_PROJECT_NAME
  return (envTheme === 'ilta' ? 'ilta' : 'legaltech') as Theme
})()

export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST
export const GRAPHQL_HOST = process.env.NEXT_PUBLIC_GRAPHGL_API_HOST
export const TWITTER_USER_TITLE = process.env.NEXT_PUBLIC_TWITTER_USER_TITLE
export const CONTACT_URL = process.env.NEXT_PUBLIC_CONTACT_US_URL
export const LEARN_MORE_URL = process.env.NEXT_PUBLIC_LEARN_MORE_URL
export const INITIAL_ITEM_CNT = 5
export const GRAPHQL_QUERY_LIMIT = 100
export const SHOW_FREE_LISTING_FORM = process.env.NEXT_PUBLIC_SHOW_FREE_LISTING_FORM
export const USE_GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS === 'true'
export const USE_FACTORS_AI = process.env.NEXT_PUBLIC_FACTORS_AI === 'true'
export const REVIEW_ENABLED = process.env.NEXT_PUBLIC_REVIEW_ENABLED === 'true'
export const RECAPTCHA_ENABLED = process.env.NEXT_PUBLIC_RECAPTCHA_ENABLED === 'true'
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!
export const MIXPANEL_PROJECT_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN
export const MIXPANEL_ENABLED = process.env.NEXT_PUBLIC_MIXPANEL_ENABLED === 'true'
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT
export const TERMS_PDF = process.env.NEXT_PUBLIC_TERMS_PDF
export const PRIVACY_PDF = process.env.NEXT_PUBLIC_PRIVACY_PDF
export const SHOW_REVIEWS = process.env.NEXT_PUBLIC_SHOW_REVIEWS === 'true'
export const SHARE_FEATURE_ENABLED = process.env.NEXT_PUBLIC_SHARE_FEATURE_ENABLED === 'true'
export const SHOW_COLLECTION = process.env.NEXT_PUBLIC_COLLECTION === 'true'

export const VIDEO = 0
export const TESTMONIAL = 1
export const SCREENSHOT = 2
export const WHITEPAPERS = 3
export const CASESTUDIES = 4
export const ARTICLES = 5
export const FOUNDER = 6
export const SPECIAL = 7
export const DEDICATED = 8
export const PRESSRELEASE = 9

export const JOB_URL = 'https://legaltechjobs.com/'

export const AMPLIFY_REGION = process.env.NEXT_PUBLIC_AMPLIFY_REGION
export const AMPLIFY_USER_POOL_ID = process.env.NEXT_PUBLIC_AMPLIFY_USER_POOL_ID
export const COGNITO_OAUTH_DOMAIN = process.env.NEXT_PUBLIC_COGNITO_OAUTH_DOMAIN

export const AMPLIFY_USER_POOL_APP_CLIENT_ID = process.env.NEXT_PUBLIC_AMPLIFY_USER_POOL_APP_CLIENT_ID
export const AMPLIFY_USER_POOL_API_CLIENT_ID = process.env.NEXT_PUBLIC_AMPLIFY_USER_POOL_API_CLIENT_ID
export const AMPLIFY_USER_POOL_API_CLIENT_SECRET = process.env.NEXT_PUBLIC_AMPLIFY_USER_POOL_API_CLIENT_SECRET

export const RESOURCE_IMPORT_SAMPLE_CSV_FILE_URL = process.env.NEXT_PUBLIC_RESOURCE_IMPORT_SAMPLE_CSV_FILE_URL
export const SHOW_ASK_AI = process.env.NEXT_PUBLIC_SHOW_ASK_AI === 'true'

export const INVITATION_THANKS_MESSAGE = 'Thanks for registering! Please check your email.'
export const VENDORSIGNUP = 'Thanks for registering! Please verify your inbox.'
